import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "className": "message is-dark"
    }}><div parentName="div" {...{
        "className": "message-body"
      }}><p parentName="div">{`Please read these Terms and Conditions carefully before using our website or placing an order. We recommend that you print a copy of these for future reference.`}</p><p parentName="div">{`By placing an order with us or using our website, you confirm that you accept these Terms and Conditions and that you agree to comply with them. If you do not agree to these Terms and Conditions, you must not proceed with placing an order or using our website in any way.`}</p></div></div>
    <h2 {...{
      "id": "who-we-are-and-how-to-contact-us"
    }}>{`Who we are and how to contact us`}</h2>
    <p>{`C19 Testing is owned and operated by Selph Limited (“We”). We are registered in England and Wales under company number 12169438 and have our registered office at 124 City Road, London, EC1V 2NX.`}</p>
    <p>{`We are a limited company.`}</p>
    <p>{`To contact us, please visit our `}<a parentName="p" {...{
        "href": "https://helpcentre.c19testing.co.uk/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Help Centre`}</a>{`. If you contact us about an order, please include your order number.`}</p>
    <h2 {...{
      "id": "how-we-will-contact-you"
    }}>{`How we will contact you`}</h2>
    <p>{`If we need to contact you we will do so by e-mail or by phone using the contact details you provide to us.`}</p>
    <p>{`By giving us your contact details you also give us your consent to contact you about the service we provide and any product you have bought or enquired about. If necessary, we may leave you a message.`}</p>
    <p>{`Test results will only be accessible if you log in to your account or register your details with us.`}</p>
    <p>{`If you are buying multiple tests on behalf of family members, you need to ensure that you have their consent to receive test results on their behalf, or they can set up their own account online or request that we send test results to a different email address as nominated by them.`}</p>
    <p>{`If you are a company or organisation buying multiple tests for your employees or staff members, each individual will be required to set up their own account online or register their personal details online and nominate an email address for us to send their test results to. They may also consent for us to share certain details with you.`}</p>
    <p>{`It is the customer’s responsibility to ensure that its contact details are kept up to date, and the test taker’s responsibility to ensure that any contact details they provide are accurate.`}</p>
    <h2 {...{
      "id": "there-are-other-terms-that-may-apply-to-you"
    }}>{`There are other terms that may apply to you`}</h2>
    <p>{`These Terms and Conditions refer to the following additional terms, which also apply to your use of our website:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.c19testing.co.uk/privacy-policy",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Our Privacy Policy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.c19testing.co.uk/privacy-policy#8-use-of-cookies",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Our Cookie Policy`}</a></li>
    </ul>
    <h2 {...{
      "id": "our-products"
    }}>{`Our products`}</h2>
    <p>{`Our products include kits to facilitate the collection of human biofluids (e.g. blood, urine, faeces, mucus and saliva) and analysis either in a laboratory or by a point-of-care assay. A kit could be designed to conduct a panel of investigations or for one particular marker, depending on the product purchased. Kits may be for either self-collection or require sample collection by a healthcare professional. We may also provide laboratory testing without the provision of testing kits where a client has their own suitable sampling methods.`}</p>
    <p>{`Where sample collection by a healthcare professional is requested, we may use our own staff or arrange for an appropriately trained third-party to attend your designated site. We accept no liability on behalf of the third-party companies with which we work. This service will be at your cost and we will provide a quote in advance for these services.`}</p>
    <p>{`When you purchase a testing kit we will post it to you with instructions of how to collect your samples and return them to a laboratory for analysis. Details of the exact tests performed are set out on our website and will depend on what you have requested. You will be provided your results either by verified email or on our website.`}</p>
    <p>{`We may contact you directly regarding your samples and test results, if necessary.`}</p>
    <p>{`A testing kit, laboratory analysis or point-of-care assay and the results make up the product that we are providing to you (“Product”). The Product will be deemed to be delivered to you once you have been sent (by email, SMS or weblink) your results.`}</p>
    <p>{`If you are an individual, you may only purchase Products from us if you are at least 18 years old. You must also provide us with certain personal information, including your name and address in order to purchase a test.`}</p>
    <p>{`You may also purchase multiple test kits in one order for the purpose of providing tests to other individuals, such as friends, family or staff. However, test kits must not be resold.`}</p>
    <p>{`Prior to returning samples to our laboratory all users are required to provide us with their name, date of birth and email address for receiving results using a secure form on our website. They must also legibly enter their details on the laboratory request form and sample labels provided in the test kit. This allows us to identify samples in the laboratory and communicate results to individuals in a secure manner. If you are providing this information on someone else’s behalf, such as a family member, you MUST ensure that the individual providing the test sample has consented to their results being sent to the email address provided. If you fail to provide the required information, we may not be able to identify your sample and you may not receive your results. We will only provide results to users who have registered their details with us. Rarely, we may share results and personal information with other businesses or legal entities to which you have consented. At times we may have a legal obligation to report your result and data to public health bodies such as the UK Health Security Agency without your explicit consent.
Where individuals are conducting point-of-care tests and uploading photos of these tests to our system for interpretation and certification, we require testees to confirm that they have conducted tests as directed by the manufacturer’s instructions. Furthermore, users are required to confirm that they have adhered to our specific test procedures including, where applicable, providing photographic evidence of identification. Failure to conduct tests in-line with manufacturer’s instructions or comply with our procedures may mean that we cannot provide your result.
Test results are for the purposes of information only. They may provide a guide to help you understand your health but do not constitute a clinical diagnosis. Although we only work with accredited laboratories, no laboratory or point-of-care test is perfect and we are not responsible for any errors or inaccuracies in test results generated by our partner laboratory or point-of-care tests. There can be instances where tests are negative despite the presence of conditions which are later found to be the cause of symptoms. Similarly, there can be instances where tests are positive but subsequent investigation fails to detect any underlying condition. Our service is not a substitute for in-person medical investigation and advice. If you have any concerns regarding your health or your test results, or of the health and results of individuals in your household or employees and third parties you decide to test (only applicable using the point-of-care assay), you should discuss them directly with a healthcare professional. We cannot take responsibility for any actions or inactions made by individuals, business or other parties in light of the test results we provide.`}</p>
    <h2 {...{
      "id": "how-a-contract-is-formed-between-you-and-us"
    }}>{`How a contract is formed between you and us`}</h2>
    <p>{`Our website will guide you through the steps you need to take to place an order with us. Alternatively, orders can be placed via telephone or email.`}</p>
    <p>{`Payment must be received in full in cleared funds before we process your order. Once we receive payment, we will confirm our acceptance to you by sending you a confirmation email (“Order Confirmation”). The contract between us will only be formed on the date we send you the Order Confirmation (the “Contract”).`}</p>
    <p>{`Kits for sample collection or point-of-care analysis remain valid for use up to six months from the date of receipt of your purchase. If you do not send samples to the laboratory within the Validity Period your purchase will expire and you will not be entitled to a refund.`}</p>
    <p>{`To be analysed, samples must be received with appropriate labels and forms completed correctly as indicated in the test kit instructions. It may not be possible to analyse unlabeled or mislabelled samples or in cases where accompanying forms are not completed correctly and refunds in such cases are at our discretion. If we are unable to analyse samples or produce a valid result for technical reasons, we will refund the cost of the test in full or offer a replacement test.`}</p>
    <p>{`By purchasing a Product and sending samples to our laboratory you are giving us and our partner laboratories your informed consent to perform the tests you have ordered.`}</p>
    <h2 {...{
      "id": "how-to-pay"
    }}>{`How to pay`}</h2>
    <p>{`You may pay for Products using a debit or credit card (including Visa, MasterCard, American Express, Visa Electron, Switch, Solo, Maestro or JCB), or by bank transfer.`}</p>
    <h2 {...{
      "id": "price-of-products-and-delivery-charges"
    }}>{`Price of products and delivery charges`}</h2>
    <p>{`The price of the Products will be as quoted on our website at the time you submit your order. If you are a company or organisation, the price of the Products will be based on the quote provided by us.`}</p>
    <p>{`We may provide the means to return samples in sample collection kits and will specify this on our website. If you choose to return any samples to our laboratory via a courier or other delivery service you will do so at your own cost.`}</p>
    <p>{`Prices for our Products may change from time to time, but changes will not affect any order you have already placed.`}</p>
    <h2 {...{
      "id": "dispatch-and-delivery"
    }}>{`Dispatch and delivery`}</h2>
    <p>{`After your payment has been processed and you have received either an Order Confirmation or a paid invoice we will send you a Product by tracked mail and you should receive it within 1 (one) working day, within most regions of the United Kingdom. A working day is any day other than a Saturday, Sunday or public holiday in the UK. However, we cannot guarantee that the testing kit will arrive within 1 (one) working days as this depends on third party delivery services (such as DPD) over which we do not have direct control. We accept no liability for losses, financial or otherwise, incurred by delayed delivery of test kits.`}</p>
    <p>{`Delivery of a testing kit shall be completed upon our first attempt to deliver it to the address you have provided and the testing kit and samples will be your responsibility from that time until it reaches our laboratory for analysis.`}</p>
    <p>{`If you lose or damage your home collection testing kit, we will send you a replacement kit subject to a discretionary charge to cover our costs in doing so.`}</p>
    <p>{`If your testing kit arrives in the post damaged to the point where it can no longer be safely used, we will send you a replacement kit as soon as you notify us.`}</p>
    <h2 {...{
      "id": "sample-return-and-test-results"
    }}>{`Sample return and test results`}</h2>
    <p>{`For laboratory tests, you must return samples to our partner laboratory within the time frames set out in the instructions. We will provide pre-paid tracked return envelopes for this purpose. When returning samples, you must follow the specific instructions provided on our website, in our correspondence with you or in the kit. Not doing so may lead to deterioration of your sample which could affect the accuracy of your test results. We do not accept responsibility for results that are compromised due to delays in returning samples, where samples are lost in the post or where samples are received unlabeled / without identification. Samples may also be returned to the laboratory in person or via courier or other service; this will be at your own cost.`}</p>
    <p>{`Occasionally, laboratories are unable to test samples sent to them. This may be because the amount collected is insufficient for full analysis, or in the case of blood samples, the sample has deteriorated or clotted or because it has haemolysed (where red blood cells damaged during sample collection leak their contents into the surrounding plasma). If the laboratory cannot test your sample, we will send a second collection kit to you free-of-charge for you to repeat the process. We can only offer a second collection kit free-of-charge and any further tests will be charged at full rate.`}</p>
    <p>{`Point-of-care tests may also fail to yield a result that can be conclusively interpreted or have faults that prevent the assay from running correctly. We do not accept liability for inconclusive or uninterpretable tests but will refund or replace such tests at our discretion.`}</p>
    <h2 {...{
      "id": "cancellations-and-refunds"
    }}>{`Cancellations and refunds`}</h2>
    <p>{`You have up to 14 calendar days from the moment we confirm acceptance of your order by email to cancel your order and receive a full refund. If your order has been sent to our logistics supplier for dispatch, you may be required to provide the unique kit ID(s) associated with your test kit(s) and return the test kit(s) to us to receive your full refund. We will confirm the return delivery address by email and you will bear the cost of return. You have 14 calendar days from the moment we accept your cancellation request and provide you a return address to provide proof of return postage and receive a full refund. We may forgo the requirement to return test kits at our discretion.`}</p>
    <p>{`Refunds will not be given for requests made after 14 calendar days from acceptance of your order.`}</p>
    <p>{`These cancellation rights do not apply if you have already sent your sample to the laboratory for analysis or broken the box seal of your point-of-care test.`}</p>
    <p>{`Your legal right to cancel a Contract starts from the date you receive the Order Confirmation or a quote if you are a company or organisation. Your 14-day deadline for cancelling the Contract in relation to any testing kit that has been dispatched starts from when we confirm order acceptance by email. If you are a company or organisation buying kits for your staff and you decide not to use the total number of Products, you can contact us to arrange for a credit note and part refund subject always to a cancellation charge.`}</p>
    <p>{`To cancel a Contract, please contact us via our `}<a parentName="p" {...{
        "href": "https://helpcentre.c19testing.co.uk/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Help Centre`}</a>{` stating that you wish to cancel and the reason for the cancellation. Your reason for cancellation will not affect your right to cancel the order. Your cancellation is effective from the date you send us the email.`}</p>
    <p>{`If you cancel your Contract within the cancellation period and you are entitled to a refund we will refund you, if applicable, within 14 days of your providing proof of goods return postage.`}</p>
    <p>{`We will refund you via the same means used to pay for the Product.`}</p>
    <h2 {...{
      "id": "we-may-make-changes-to-these-terms"
    }}>{`We may make changes to these terms`}</h2>
    <p>{`We amend these Terms and Conditions from time to time and you should check them from time to time so you can be happy with any changes that are relevant to you.`}</p>
    <h2 {...{
      "id": "we-may-make-changes-to-our-website"
    }}>{`We may make changes to our website`}</h2>
    <p>{`We may update and change our website from time to time to reflect changes to our service and our users’ needs.`}</p>
    <h2 {...{
      "id": "we-may-suspend-or-withdraw-our-website"
    }}>{`We may suspend or withdraw our website`}</h2>
    <p>{`Our website is made available free of charge.`}</p>
    <p>{`We do not guarantee that our website, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our website for business and operational reasons.`}</p>
    <p>{`We will try to give you reasonable notice of any suspension or withdrawal.`}</p>
    <p>{`You are also responsible for ensuring that all persons who access our website through your internet connection are aware of these Terms and Conditions and other applicable terms, and that they comply with them.`}</p>
    <h2 {...{
      "id": "our-website-is-only-for-users-in-united-kingdom"
    }}>{`Our website is only for users in United Kingdom`}</h2>
    <p>{`Our website is directed to people residing in the United Kingdom. We do not represent that content available on or through our website is appropriate for use or available in other locations.`}</p>
    <h2 {...{
      "id": "how-you-may-use-material-on-our-website"
    }}>{`How you may use material on our website`}</h2>
    <p>{`We are the owner or the licensee of all intellectual property rights in our website, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.`}</p>
    <p>{`You may print off one copy, and may download extracts, of any page(s) from our website for your personal use and you may draw the attention of others within your organisation to content posted on our website.`}</p>
    <p>{`You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.`}</p>
    <p>{`Our status (and that of any identified contributors) as the authors of content on our website must always be acknowledged.`}</p>
    <p>{`You must not use any part of the content on our website for commercial purposes without obtaining a license to do so from us or our licensors.`}</p>
    <p>{`If you print off, copy or download any part of our website in breach of these Terms and Conditions, your right to use our website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.`}</p>
    <h2 {...{
      "id": "do-not-rely-on-information-on-this-site"
    }}>{`Do not rely on information on this site`}</h2>
    <p>{`The content on our website is provided for general information only, as a first port of call to find healthcare information. It is not intended to amount to medical advice on which you should rely.`}</p>
    <p>{`Although we make reasonable efforts to update the information on our website, we make no representations, warranties or guarantees, whether express or implied, that the content on our website is accurate, complete or up to date.`}</p>
    <h2 {...{
      "id": "we-are-not-responsible-for-websites-we-link-to"
    }}>{`We are not responsible for websites we link to`}</h2>
    <p>{`Where our website contains links to other websites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.`}</p>
    <p>{`We have no control over the contents of those websites or resources.`}</p>
    <h2 {...{
      "id": "our-responsibility-for-loss-or-damage-suffered-by-you"
    }}>{`Our responsibility for loss or damage suffered by you`}</h2>
    <p>{`The Products you purchase from us are for private use only and you agree not to use the Products for any commercial purpose. We are not liable to you for any loss of profit, loss of business or revenue, business interruption or loss of business opportunity.`}</p>
    <p>{`Our total liability to you resulting from a Contract is limited to the total value of that Contract - meaning the price you paid for the Products and any additional services. In particular we are not liable for any costs, loss, delay, inconvenience or damage you suffer as a result of:`}</p>
    <ul>
      <li parentName="ul">{`decisions you make based on the result of the laboratory analysis and/or point-of-care tests that affects your health and/or that of others;`}</li>
      <li parentName="ul">{`delays in delivery of test kits;`}</li>
      <li parentName="ul">{`the samples you send being lost, delayed or damaged in transit or via post from you to us;`}</li>
      <li parentName="ul">{`the laboratory being unable to test your samples for any reason;`}</li>
      <li parentName="ul">{`any injury you may suffer when undergoing sample collection by a third party (e.g. swab or venipuncture) or when using kits for self-collection unless the collection kit is shown to be defective;`}</li>
      <li parentName="ul">{`test results not being made available to you within the turnaround time stated for any Product;`}</li>
      <li parentName="ul">{`your failure to follow instructions provided to you in relation to sample collection or pre-sample preparation requirements;`}</li>
      <li parentName="ul">{`actions or inactions made by third parties in light of your voluntary disclosure of test results;`}</li>
      <li parentName="ul">{`your failure to act upon our advice if we recommend that you seek medical advice or attention having taken a test;`}</li>
      <li parentName="ul">{`your failure to show test results to your GP or doctor;`}</li>
      <li parentName="ul">{`your failure to send a sample to the laboratory for testing within the Validity Period;`}</li>
      <li parentName="ul">{`loss or damage that is not foreseeable;`}</li>
    </ul>
    <p>{`If we fail to comply with the Terms and Conditions, we are responsible for loss or damage you suffer that is a foreseeable result of our breach of the Terms and Conditions or our negligence. Loss or damage is foreseeable if it is an obvious consequence of our breach or if it was contemplated by you and us at the time we entered into this Contract.`}</p>
    <p>{`It is your responsibility to ensure that the relevant sample has been collected and sent to our laboratory within the Validity Period.`}</p>
    <p>{`We do not in any way exclude or limit our liability for:`}</p>
    <ul>
      <li parentName="ul">{`death or personal injury caused by our negligence;`}</li>
      <li parentName="ul">{`fraud or fraudulent misrepresentation;`}</li>
      <li parentName="ul">{`any breach of the terms implied by section 2 and section 12 of the Sale of Goods Act 1979 (title and quiet possession) as amended by the Consumer Rights Act 2015;`}</li>
      <li parentName="ul">{`any breach of the terms implied by section 9 to 11 of the Consumer Rights Act 2015 (description, satisfactory quality, fitness for purpose and samples); and`}</li>
      <li parentName="ul">{`defective products under the Consumer Protection Act 1987.`}</li>
    </ul>
    <h2 {...{
      "id": "events-outside-our-control"
    }}>{`Events outside our control`}</h2>
    <p>{`We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under a Contract that is caused by any act or event beyond our reasonable control (an “Event Outside Our Control”).`}</p>
    <p>{`If an Event Outside Our Control takes place that affects the performance of our obligations under a Contract:`}</p>
    <ul>
      <li parentName="ul">{`we will contact you as soon as reasonably possible to notify you; and`}</li>
      <li parentName="ul">{`our obligations under a Contract will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control. Where the Event Outside Our Control affects our delivery of testing kits to you, we will arrange a new delivery date with you once the Event Outside Our Control is over.`}</li>
    </ul>
    <p>{`You may cancel a Contract affected by an Event Outside Our Control which has continued for more than 30 days. To cancel, please contact us. If you opt to cancel, we will refund in full the total price you have paid for the Contract.`}</p>
    <h2 {...{
      "id": "how-we-may-use-your-personal-information"
    }}>{`How we may use your personal information`}</h2>
    <p>{`We will only use your personal information as set out in our `}<a parentName="p" {...{
        "href": "https://www.c19testing.co.uk/privacy-policy",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`privacy policy`}</a>{`. The Cookies we use on the website are explained `}<a parentName="p" {...{
        "href": "https://www.c19testing.co.uk/privacy-policy#8-use-of-cookies",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "rules-about-linking-to-our-website"
    }}>{`Rules about linking to our website`}</h2>
    <p>{`You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.`}</p>
    <p>{`You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.`}</p>
    <p>{`You must not establish a link to our website in any website that is not owned by you.`}</p>
    <p>{`Our website must not be framed on any other website, nor may you create a link to any part of our website other than the home page.`}</p>
    <p>{`We reserve the right to withdraw linking permission without notice.`}</p>
    <h2 {...{
      "id": "which-countrys-laws-apply-to-any-disputes"
    }}>{`Which country’s laws apply to any disputes`}</h2>
    <p>{`These Terms and Conditions, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.`}</p>
    <p><strong parentName="p">{`Last updated: 23/08/2022`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      