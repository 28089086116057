import { GatsbySeo } from 'gatsby-plugin-next-seo'

const MdxLayout = ({ pageContext, children }) => (
  <>
    <GatsbySeo title={pageContext.frontmatter.title} />

    <section className="section is-small">
      <div className="container">
        <p className="title has-text-midnightBlue">{pageContext.frontmatter.title}</p>
        <div className="content">{children}</div>
      </div>
    </section>
  </>
)

export default MdxLayout
